import React, { useState } from "react";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { ReactComponent as DeleteIcon } from "../assets/icons/delete.svg";
import { ReactComponent as AddIcon } from "../assets/icons/add.svg";
import { ReactComponent as SmallArrowDownIcon } from "../assets/icons/small-arrow-down.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit-outlined.svg";
import { ReactComponent as CheckIcon } from "../assets/icons/check.svg";
import { ReactComponent as LockIcon } from "../assets/icons/lock.svg";
import { ReactComponent as DragIconCatIcon } from "../assets/icons/drag-icon-cat.svg";

import { getCategories } from "../utils/getCategories";
import { Switch } from "antd";
import { Header } from "./layout/Header";

// List Page

export function ItemList() {
  const firestore = firebase.firestore();
  const categoriesRef = firestore.collection("Category");
  const queryCategory = categoriesRef.orderBy("name");
  const [categories] = useCollectionData(queryCategory, { idField: "id" });
  const [formCatValue, setFormCatValue] = useState("");

  const [addCatMenuToggle, setAddCatMenuToggle] = useState(false);
  const addCatMenuShowState = addCatMenuToggle ? "--show" : "";

  const [toggleAll, setToggleAll] = useState(false);

  const showAddCatMenu = () => {
    addCatMenuToggle ? setAddCatMenuToggle(false) : setAddCatMenuToggle(true);
  };

  const addCat = async (e) => {
    e.preventDefault();

    if (formCatValue !== "") {
      await categoriesRef.add({
        name: formCatValue,
      });
    }

    setFormCatValue("");
  };

  return (
    <>
      <section>
        <Header name="Liste de courses" action={showAddCatMenu} />
        <form className={"m-itemForm " + addCatMenuShowState} onSubmit={addCat}>
          <div className="fields">
            <div className="label">Ajouter une catégorie</div>
            <input
              value={formCatValue}
              onChange={(e) => setFormCatValue(e.target.value)}
              autoFocus
              placeholder="Nom"
            />
          </div>
          <button className="btn" type="submit" onClick={showAddCatMenu}>
            <div className="text">Ajouter</div>
          </button>
          <div className="btnCancel" onClick={showAddCatMenu}>
            <div className="text">Annuler</div>
          </div>
        </form>

        <div className="m-manageList">
          <DeleteAll />
          <div className="openAll">
            Ouvrir tous
            <ToggleOpenAll toggleAll={toggleAll} setToggleAll={setToggleAll} />
          </div>
        </div>

        {categories &&
          categories.map((category, index) => {
            return (
              <Category
                opened={toggleAll}
                setToggleAll={setToggleAll}
                id={category.id}
                name={category.name}
              />
            );
          })}
      </section>
    </>
  );
}

function ToggleOpenAll({ toggleAll, setToggleAll }) {
  const toggler = () => {
    toggleAll ? setToggleAll(false) : setToggleAll(true);
  };

  return <Switch className="toggleOpenAll" onClick={toggler} />;
}

// Category

function Category({ id, name, opened, setToggleAll }) {
  const firestore = firebase.firestore();
  const itemsRef = firestore.collection("Items");
  const categoryRef = firestore.collection("Category").doc(id);
  const [formValue, setFormValue] = useState("");
  const [formValueQuantity, setformValueQuantity] = useState("");
  const query = itemsRef.where("category", "==", categoryRef);
  const [items, loading, error] = useCollectionData(query, { idField: "id" });
  const [toggle, setToggle] = useState(false);
  // const [listOpenState, setListOpenState] = useState("");

  // const openClass = opened ? "--open" : "";

  const listOpenState = opened | toggle ? "--open" : "";

  const [addMenuToggle, setAddMenuToggle] = useState(false);
  const addMenuShowState = addMenuToggle ? "--show" : "";
  const [confirmToggle, setConfirmToggle] = useState(false);
  const confirmShowState = confirmToggle ? "--show" : "";

  const showConfirm = () => {
    confirmToggle ? setConfirmToggle(false) : setConfirmToggle(true);
  };

  const removeItem = async (e) => {
    confirmToggle ? setConfirmToggle(false) : setConfirmToggle(true);

    categoryRef.delete();
  };

  const showAddMenu = () => {
    addMenuToggle ? setAddMenuToggle(false) : setAddMenuToggle(true);
  };

  const addItem = async (e) => {
    e.preventDefault();

    if (formValue !== "") {
      await itemsRef.add({
        name: formValue,
        quantity: formValueQuantity,
        category: categoryRef,
        checked: false,
        locked: false,
      });
    }

    setFormValue("");
    setformValueQuantity("");
  };

  const catToggler = () => {
    if (opened === false) {
      toggle ? setToggle(false) : setToggle(true);
    }

    // if (toggle === false && opened === false) {
    //   setToggleAll(false);
    // }
  };

  return (
    <ul className={"o-groceryList " + listOpenState}>
      <div className="listHead">
        <div className="dragHandle">
          <DragIconCatIcon />
        </div>
        <div className="category" onClick={catToggler}>
          <SmallArrowDownIcon />
          <span className="categoryName">{name}</span>
        </div>
        <div className="actions">
          <button className="delete" onClick={() => showConfirm()}>
            <DeleteIcon />
          </button>
          <div className={"m-itemForm confirm " + confirmShowState}>
            <div className="fields">
              <div className="label">
                Souhaitez-vous supprimer la catégorie {name} ?
              </div>
            </div>
            <div className="btn" onClick={() => removeItem(id)}>
              <div className="text">Confirmer</div>
            </div>
            <div className="btnCancel" onClick={() => showConfirm()}>
              <div className="text">Annuler</div>
            </div>
          </div>
          <button className="btnAdd" onClick={showAddMenu}>
            <AddIcon />
          </button>
          <form className={"m-itemForm " + addMenuShowState} onSubmit={addItem}>
            <div className="fields">
              <div className="label">Ajouter un item</div>
              <input
                value={formValue}
                onChange={(e) => setFormValue(e.target.value)}
                autoFocus
                placeholder="Nom max "
              />
              <input
                value={formValueQuantity}
                onChange={(e) => setformValueQuantity(e.target.value)}
                placeholder="Quantité"
              />
            </div>
            <button className="btn" type="submit" onClick={showAddMenu}>
              <div className="text">Ajouter</div>
            </button>
            <div className="btnCancel" onClick={showAddMenu}>
              <div className="text">Annuler</div>
            </div>
          </form>
        </div>
      </div>
      {items && items.map((item) => <ListItem key={item.id} item={item} />)}
    </ul>
  );
}

function DeleteAll() {
  const firestore = firebase.firestore();
  const itemsRef = firestore.collection("Items");
  const query = itemsRef.where("locked", "==", false);
  const [items] = useCollectionData(query, { idField: "id" });

  const [confirmToggle, setConfirmToggle] = useState(false);
  const confirmShowState = confirmToggle ? "--show" : "";

  const showConfirm = () => {
    confirmToggle ? setConfirmToggle(false) : setConfirmToggle(true);
  };

  const removeAllItems = () => {
    items.forEach((item) => {
      const itemRef = firestore.collection("Items").doc(item.id);

      itemRef.delete();
      showConfirm();
    });
  };

  return (
    <div>
      <button className="deleteAll" onClick={() => showConfirm()}>
        <span className="text"> Vider la liste</span>
        <DeleteIcon />
      </button>
      <div className={"m-itemForm confirm " + confirmShowState}>
        <div className="fields">
          <div className="label">Souhaitez-vous supprimer la liste ?</div>
        </div>
        <div className="btn" onClick={() => removeAllItems()}>
          <div className="text">Confirmer</div>
        </div>
        <div className="btnCancel" onClick={() => showConfirm()}>
          <div className="text">Annuler</div>
        </div>
      </div>
    </div>
  );
}

// Item

function ListItem(props) {
  const firestore = firebase.firestore();

  const { name, id, quantity, checked, locked } = props.item;
  const itemsRef = firestore.collection("Items").doc(id);

  const checkClass = checked ? "--checked" : "";
  const lockClass = locked ? "--locked" : "";

  const [editMenuToggle, setEditMenuToggle] = useState(false);
  const editMenuShowState = editMenuToggle ? "--show" : "";

  const [formValue, setFormValue] = useState(name);
  const [formValueQuantity, setformValueQuantity] = useState(quantity);
  const categoryRef = firestore.collection("Category").doc(id);

  const [confirmToggle, setConfirmToggle] = useState(false);
  const confirmShowState = confirmToggle ? "--show" : "";

  const showConfirm = () => {
    confirmToggle ? setConfirmToggle(false) : setConfirmToggle(true);
  };

  const removeItem = (item) => {
    itemsRef.delete();
  };

  const showEditMenu = () => {
    editMenuToggle ? setEditMenuToggle(false) : setEditMenuToggle(true);
  };

  const editItem = async (e) => {
    e.preventDefault();

    await itemsRef.update({
      name: formValue,
      quantity: formValueQuantity,
    });
  };

  const checkItem = async (e) => {
    if (checked === false) {
      e.preventDefault();
      await itemsRef.update({
        checked: true,
      });
    } else {
      e.preventDefault();
      await itemsRef.update({
        checked: false,
      });
    }
  };

  const lockItem = async (e) => {
    if (locked === false) {
      e.preventDefault();
      await itemsRef.update({
        locked: true,
      });
    } else {
      e.preventDefault();
      await itemsRef.update({
        locked: false,
      });
    }
  };

  return (
    <li className={"m-groceryItem " + checkClass + " " + lockClass}>
      <button className="lock" onClick={lockItem}>
        <LockIcon />
      </button>
      <div className="field" onClick={checkItem}>
        <div className="input">
          <CheckIcon />
        </div>
        <div className="info">
          <div className="label">{name}</div>
          <div className="quantity">{quantity}</div>
        </div>
      </div>
      <div className="action">
        <button className="edit" onClick={() => showEditMenu()}>
          <EditIcon />
        </button>
        <button className="delete" onClick={() => showConfirm()}>
          <DeleteIcon />
        </button>
        <div className={"m-itemForm confirm " + confirmShowState}>
          <div className="fields">
            <div className="label">
              Souhaitez-vous supprimer l'item {name} ?
            </div>
          </div>
          <div className="btn" onClick={() => removeItem(id)}>
            <div className="text">Confirmer</div>
          </div>
          <div className="btnCancel" onClick={() => showConfirm()}>
            <div className="text">Annuler</div>
          </div>
        </div>
      </div>

      <form
        className={"m-itemForm editItem " + editMenuShowState}
        onSubmit={editItem}
      >
        <div className="fields">
          <div className="label">Modifier un item</div>

          <input
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            placeholder="Nom"
          />
          <input
            value={formValueQuantity}
            onChange={(e) => setformValueQuantity(e.target.value)}
            placeholder="Quantité"
          />
        </div>
        <button className="btn" type="submit" onClick={showEditMenu}>
          <div className="text">Modifier</div>
        </button>
        <div className="btnCancel" onClick={showEditMenu}>
          <div className="text">Annuler</div>
        </div>
      </form>
    </li>
  );
}
