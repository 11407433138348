import React from "react";
import { SignOut } from "../sign-out";
import { Header } from "../layout/Header";

export function Profil(props) {
  return (
    <section className="o-profil">
      <Header name="Profil" />

      <div className="content">
        <h2 className="title">
          Bonjour cher <span>{props.userName}</span>
        </h2>

        <div>
          <SignOut />
        </div>
      </div>
    </section>
  );
}
