import React, { useState } from "react";
import firebase from "firebase/app";
import { AddButton } from "../AddButton";
import dayjs from "dayjs";

export function TaskModale(props) {
  const firestore = firebase.firestore();
  const tasksRef = firestore.collection("tasks");
  const today = dayjs();

  // Toggle modale state
  const [toggleModaleState, settoggleModaleStatee] = useState(false);
  const modaleState = toggleModaleState ? "--show" : "";
  const toggleModale = () => {
    toggleModaleState
      ? settoggleModaleStatee(false)
      : settoggleModaleStatee(true);
  };

  // Generate time stamp
  const getTimeStamp = (timer, today) => {
    const h = parseInt(timer.substr(0, timer.indexOf(":")));
    const m = parseInt(timer.substr(timer.indexOf(":") + 1));
    const targetTime = today.add(h, "hour").add(m, "minute");
    const result = targetTime.valueOf();

    return result;
  };

  // Add new task
  const [formTitleValue, setformTitleValue] = useState("");
  const [formTimerValue, setformTimerValue] = useState("");
  const addTask = async (e) => {
    e.preventDefault();
    const timestamp = getTimeStamp(formTimerValue, today);

    if (formTitleValue !== "") {
      await tasksRef.add({
        Title: formTitleValue,
        createdAt: today.valueOf(),
        timestamp: timestamp,
        stepTrigger: dayjs(timestamp).diff(today, "seconds") / 3,
        priority: 1,
      });
    }
    setformTitleValue("");
    setformTimerValue("");
  };
  return (
    <div>
      <div onClick={toggleModale}>
        <AddButton />
      </div>
      {/* Formulaire d'ajoût de tâche */}
      <form className={"m-taskModale " + modaleState} onSubmit={addTask}>
        <div className="action">
          <div className="cancel" onClick={toggleModale}>
            Annuler
          </div>
          <div className="title">Nouvelle tâche</div>
          <button className="submit" type="submit" onClick={toggleModale}>
            Ajouter
          </button>
        </div>

        <h2 className="title">
          Configurez votre <span>tâche</span> :
        </h2>

        <div className="a-field">
          <input
            type="text"
            value={formTitleValue}
            onChange={(e) => setformTitleValue(e.target.value)}
            autoFocus
            placeholder="Titre (max 40)"
            maxLength="40"
          />
        </div>
        <div className="a-field timer">
          <label>Durée de la tâche (hh:mm):</label>
          <input
            id="timer"
            type="time"
            value={formTimerValue || "00:00"}
            onChange={(e) => setformTimerValue(e.target.value)}
            autoFocus
          />
        </div>
      </form>
    </div>
  );
}
