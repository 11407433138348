import firebase from "firebase/app";

export function SignOut() {
  const auth = firebase.auth();

  return (
    auth.currentUser && (
      <button
        className="a-btn --primary sign-out"
        onClick={() => auth.signOut()}
      >
        Se déconnecter
      </button>
    )
  );
}
