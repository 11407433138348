import dayjs from "dayjs";
import firebase from "firebase/app";

export function getRemainingTimeUntilMsTimestamp(timestampMs, step2, id) {
  const timestampDayjs = dayjs(timestampMs);
  const nowDayjs = dayjs();
  const step2Countdown = timestampDayjs.diff(nowDayjs, "seconds");
  const firestore = firebase.firestore();
  const taskRef = firestore.collection("tasks").doc(id);
  const updateTask = async (p) => {
    await taskRef.update({
      priority: p,
    });
  };

  if (timestampDayjs.isBefore(nowDayjs)) {
    updateTask(3);
    return {
      seconds: "",
      minutes: "",
      hours: "Tâche prête",
      step: "high",
    };
  } else if (step2Countdown <= step2) {
    updateTask(2);

    return {
      seconds: getRemainingSeconds(nowDayjs, timestampDayjs),
      minutes: getRemainingMinutes(nowDayjs, timestampDayjs) + ":",
      hours: getRemainingHours(nowDayjs, timestampDayjs) + ":",
      step: "medium",
    };
  }
  return {
    seconds: getRemainingSeconds(nowDayjs, timestampDayjs),
    minutes: getRemainingMinutes(nowDayjs, timestampDayjs) + ":",
    hours: getRemainingHours(nowDayjs, timestampDayjs) + ":",
    step: "low",
  };
}

function getRemainingSeconds(nowDayjs, timestampDayjs) {
  const seconds = timestampDayjs.diff(nowDayjs, "seconds") % 60;
  return padWithZeros(seconds, 2);
}

function getRemainingMinutes(nowDayjs, timestampDayjs) {
  const minutes = timestampDayjs.diff(nowDayjs, "minutes") % 60;
  return padWithZeros(minutes, 2);
}

function getRemainingHours(nowDayjs, timestampDayjs) {
  const hours = timestampDayjs.diff(nowDayjs, "hours") % 24;
  return padWithZeros(hours, 2);
}

function padWithZeros(number, minLength) {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
}
