import React from "react";
import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";

export function AddButton() {
  return (
    <div className="a-addButton">
      <AddIcon />
    </div>
  );
}
