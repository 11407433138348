import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo48.svg";

export function Loading() {
  return (
    <div className="m-loading">
      <LogoIcon />
    </div>
  );
}
