import React from "react";

import { ReactComponent as HomeOutlinedIcon } from "../assets/icons/home-outlined.svg";
import { ReactComponent as GroceryOutlinedIcon } from "../assets/icons/grocery-outlined.svg";
import { ReactComponent as ProfilOutlinedIcon } from "../assets/icons/profil-outlined.svg";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

function HeaderView() {
  const location = useLocation();

  if (location === "/courses") {
  }
  return <span>Path : {location.pathname}</span>;
}

export function Navbar(props) {
  const location = useLocation();

  console.log("current path", location);

  return (
    <nav className="m-primaryNav">
      <ul className="navList">
        <li className="navItem">
          <Link to="/">
            <HomeOutlinedIcon className="dashboard" />
            Accueil
          </Link>
        </li>
        <li className="navItem">
          <Link to="/courses">
            <GroceryOutlinedIcon className="courses" />
            Courses
          </Link>
        </li>
        <li className="navItem">
          <Link to="/profil">
            <ProfilOutlinedIcon className="profil" />
            Profil
          </Link>
        </li>
      </ul>
    </nav>
  );
}
