import React, { useState } from "react";
import { Header } from "../layout/Header";
import { TaskModale } from "../Tasks/TaskModale";
import { Task } from "./Task";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import dayjs from "dayjs";
import locale from "../../utils/fr";

export function Dashboard() {
  // Read tasks from db
  const firestore = firebase.firestore();
  const tasksRef = firestore.collection("tasks");
  const queryTask = tasksRef.orderBy("priority", "desc");
  const [tasks] = useCollectionData(queryTask, { idField: "id" });

  // Set curent Date
  const today = dayjs(),
    date = today.locale(locale, null, true).format("dddd D MMMM");

  return (
    <section>
      <Header name="Co-tidien" />
      <TaskModale />
      <div className="m-intro">
        <h2 className="title">
          Tableau de <span>bord</span>
        </h2>
        <LogoIcon />
      </div>

      <div className="m-date">
        <div className="today">
          Aujourd'hui : <span>{date}</span>
        </div>

        <div className="tomorrow">
          Demain
          <ArrowRightIcon />
        </div>
      </div>

      <div className="o-feed">
        {tasks &&
          tasks.map((task, index) => {
            return <Task key={task.id} id={task.id} task={task} />;
          })}
      </div>
    </section>
  );
}
