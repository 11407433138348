import React from "react";
import { ReactComponent as ManageIcon } from "../../assets/icons/manage.svg";
import { ReactComponent as ProfilCircleIcon } from "../../assets/icons/profil-circle.svg";

export function Header(props) {
  return (
    <header className="o-header">
      <ProfilCircleIcon className="icon" />
      <h1 className="title">{props.name}</h1>
      <ManageIcon className="icon" onClick={props.action} />
    </header>
  );
}
