import firebase from "firebase/app";
import { ReactComponent as LogoIcon } from "../assets/icons/logo48.svg";
import { ReactComponent as GoogleIcon } from "../assets/icons/google.svg";
import { Loading } from "./layout/Loading";

const useSignIn = () => {
  const signInWithGoogle = () => {
    const auth = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return signInWithGoogle;
};

export function SignIn() {
  const signInWithGoogle = useSignIn();

  return (
    <main className="o-login">
      <div className="brand">
        <LogoIcon />

        <h2 className="title">
          <span>Co</span>-tidien
        </h2>
        <p>Simplifiez votre quotidien !</p>
      </div>
      <button className="a-btn sign-in" onClick={signInWithGoogle}>
        <span>Se connecter avec Google</span>
        <GoogleIcon />
      </button>
    </main>
  );
}
