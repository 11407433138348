import { useState, useEffect } from "react";
import { ReactComponent as TaskIcon } from "../../assets/icons/task.svg";
import { ReactComponent as EditDotIcon } from "../../assets/icons/edit-dot.svg";
import { getRemainingTimeUntilMsTimestamp } from "../../utils/CountdownTimerUtils";
import firebase from "firebase/app";
import dayjs from "dayjs";

export function Task(props) {
  const firestore = firebase.firestore();
  const taskRef = firestore.collection("tasks").doc(props.id);
  const task = props.task;
  const id = task.id;

  const defaultRemainingTime = {
    seconds: "00",
    minutes: "00:",
    hours: "00:",
    step: "low",
  };

  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(task.timestamp, task.stepTrigger, id);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [task.timestamp, task.stepTrigger, id]);

  function updateRemainingTime(countdown, step2, id) {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown, step2, id));
  }

  const removeTask = async (e) => {
    taskRef.delete();
  };

  return (
    <div className={"m-task " + remainingTime.step}>
      <div className="header">
        {task.timestamp ? (
          <div className="type" onClick={removeTask}>
            <TaskIcon />
            <div className="timer">
              <span className="hours">{remainingTime.hours}</span>
              <span className="minutes">{remainingTime.minutes}</span>
              <span className="seconds">{remainingTime.seconds}</span>
            </div>
          </div>
        ) : (
          <div className="type" onClick={removeTask}>
            <TaskIcon />
          </div>
        )}
        <EditDotIcon className="edit" />
      </div>
      <div className="title">{task.Title}</div>
    </div>
  );
}
