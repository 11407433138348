import React from "react";
import "./assets/scss/App.scss";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { SignIn } from "./components/sign-in";
import { ItemList } from "./components/item-list";
import { Navbar } from "./components/navbar";
import { Dashboard } from "./components/Dashboard/Index";
import { Profil } from "./components/Profil/index";
import { Loading } from "./components/layout/Loading";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

firebase.initializeApp({
  apiKey: "AIzaSyAus1_KKCgGJGer3j7NxSXyfFDVn0yW91g",
  authDomain: "co-tidien.shailashbhati.fr",
  projectId: "meal-planner-1dde5",
  storageBucket: "meal-planner-1dde5.appspot.com",
  messagingSenderId: "970370989047",
  appId: "1:970370989047:web:43e497fc022031b6b5ccf2",
});

const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user] = useAuthState(auth);
  console.log(user);

  var curentUser = firebase.auth().currentUser;
  var name, email, photoUrl, uid, emailVerified;

  if (curentUser != null) {
    name = curentUser.displayName;
  }

  return (
    <div className="App">
      <Loading />
      {user ? (
        <body>
          <Navbar></Navbar>

          <main>
            <Switch>
              <Route path="/courses">
                <ItemList />
              </Route>
              <Route path="/profil">
                <Profil userName={name} />
              </Route>
              <Route path="/">
                <Dashboard />
              </Route>
            </Switch>
          </main>
        </body>
      ) : (
        <SignIn />
      )}
    </div>
  );
}

export default App;
